import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby'
import _ from 'lodash'
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from '../components/Layout'



export const PageTemplate = ({ title, content, featuredMedia }) => {
  const heroImg = _.get(featuredMedia, 'localFile.childImageSharp.fluid');
  const PageWrapper = styled.section`
    min-height: 80vh;
    max-width: 800px;
    margin: 0 auto;
  `;

  const Hero = styled.div`
    position: relative;
    height: 0;
    top: 50px;
    padding-bottom: 30%;
    margin-bottom: 50px;
  `

  const Image = styled(Img)`
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `

  return (
    <PageWrapper className="section section--gradient">
      {heroImg && (
        <Hero>
          <Image fluid={heroImg} style={{position: `absolute`}} />
        </Hero>
      )}
      <div className="container">
        <div className="content m-t-xl">
          <h1 className="title is-size-1 has-text-weight-bold is-bold-light has-text-centered m-b-xxl">
            {title}
          </h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </PageWrapper>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  featuredMedia: PropTypes.object
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data
  const { title: siteTitle } = data.site.siteMetadata

  return (
    <Layout>
      <Helmet title={`${page.title} | ${siteTitle}`} />
      <PageTemplate title={page.title} content={page.content} featuredMedia={page.featured_media} />
    </Layout>
  )
}

Page.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
